<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Hello World !</v-card-title>
          <v-card-text>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus ratione laboriosam hic sit, vitae, rem cumque odit eius quae obcaecati ipsam possimus aliquid modi facere numquam deleniti natus, asperiores velit!</p>
          </v-card-text>
          <v-card-actions>
            <v-alert type="success">
              Salut
            </v-alert>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
};
</script>
